<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://www.facebook.com/PolizaJuridicaMx/"> Facebook </a>
          </li>
          <li>
            <a href="https://www.instagram.com/polizajuridica"> Instagram </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/p%C3%B3liza-jur%C3%ADdica"
            >
              Linkedin
            </a>
          </li>
          <li>
            <a href="https://old.polizajuridica.com.mx/"> Iniciar Sesión (Sispo) </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Póliza Jurídica
        <a :href="aviso"> Todos los derechos reservados Aviso de privacidad</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      aviso: "https://storage-pj.s3.amazonaws.com/Formatos/AVISOPRIVACIDAD.pdf",
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
